import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { STEP_STATUS, STEP_TYPE, StepInfo } from '@/components/stepper/stepper.types';
import { SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useSingleSideMilkomedaWSCBridge } from '@/store/modules/single-side/useSingleSideMilkomedaWSCBridge';
import { useSingleSideMilkomedaWSCUnwrapBridge } from '@/store/modules/single-side/useSingleSideMilkomedaWSCUnwrapBridge';
import { useSingleSideWithdraw } from './useSingleSideWithdraw';

interface ISingleSideWithdrawStepsState {
  steps: StepInfo[];
  currentStep: StepInfo | null;
  isShown: boolean;
}

type SingleSideWithdraw = ReturnType<typeof useSingleSideWithdraw>;

export const useSingleSideWithdrawSteps = defineStore('singleSideWithdrawSteps', () => {
  const { t } = useI18n();
  const { walletState } = useWallet();
  const { milkomedaWSCBridgeState, doBridgeFromCardano } = useSingleSideMilkomedaWSCBridge();
  const { milkomedaWSCUnwrapBridgeState, doBridgeFromMilkomeda } =
    useSingleSideMilkomedaWSCUnwrapBridge();

  const walletName = computed(() => {
    return walletState.wallets[SELECTED_NETWORK_NAME].connector.name;
  });

  let nextStep = {};
  let singleSideWithdraw: SingleSideWithdraw | null = null;

  const doStepAction = {
    [STEP_TYPE.BRIDGE]: async () => {
      await doBridgeFromCardano();
    },
    [STEP_TYPE.APPROVE]: async () => {
      await singleSideWithdraw!.setAllowance();
    },
    [STEP_TYPE.SIGN]: async () => {
      await singleSideWithdraw!.doWithdraw();
    },
    [STEP_TYPE.REVERSE_BRIDGE]: async () => {
      await doBridgeFromMilkomeda();
    },
  };

  const singleSideWithdrawSteps = reactive<ISingleSideWithdrawStepsState>({
    steps: [],
    currentStep: null,
    isShown: false,
  });

  function $reset() {
    singleSideWithdraw = null;
    nextStep = {};
    singleSideWithdrawSteps.steps = [];
    singleSideWithdrawSteps.currentStep = null;
    singleSideWithdrawSteps.isShown = false;
  }

  // BRIDGE
  function addBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCBridgeState.needBridge) {
      const step = {
        name: t('singleSide.steps.bridge'),
        type: STEP_TYPE.BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      singleSideWithdrawSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      return {
        prevStep: STEP_TYPE.BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // APPROVE
  function addApproveStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (!singleSideWithdraw?.singleSideForm.hasAllowance) {
      const step = {
        name: t('singleSide.steps.approve'),
        type: STEP_TYPE.APPROVE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      singleSideWithdrawSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.APPROVE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // SIGN
  function addSignStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    const step = {
      name: t('singleSide.steps.sign.withdraw'),
      type: STEP_TYPE.SIGN,
      status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
    };
    singleSideWithdrawSteps.steps.push(step);
    // set current step
    if (!currentStep) {
      currentStep = step;
    }
    // next step
    if (prevStep) {
      nextStep[prevStep] = step;
    }

    return {
      prevStep: STEP_TYPE.SIGN,
      currentStep,
    };
  }

  // REVERSE BRIDGE
  function addReverseBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCUnwrapBridgeState.needRevertBridge) {
      const step = {
        name: t('singleSide.steps.reverseBridge', { wallet: walletName.value }),
        type: STEP_TYPE.REVERSE_BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      singleSideWithdrawSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.REVERSE_BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  function setSingleSideWithdrawStore(store: SingleSideWithdraw) {
    singleSideWithdraw = store;
  }

  function prepareSteps(): void {
    let step: StepInfo | null = null;
    let prevStep: STEP_TYPE | null = null;

    let state = addBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addApproveStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addSignStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addReverseBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    // next step
    if (prevStep) {
      nextStep[prevStep] = null;
    }

    singleSideWithdrawSteps.currentStep = step;
  }

  async function doStep(step: StepInfo) {
    step.status = STEP_STATUS.PROGRESS;
    await doStepAction[step.type]();
    step.status = STEP_STATUS.SUCCESS;
    singleSideWithdrawSteps.currentStep = nextStep[step.type];
  }

  async function runSteps() {
    if (!singleSideWithdrawSteps.currentStep) return;

    try {
      while (singleSideWithdrawSteps.currentStep) {
        await doStep(singleSideWithdrawSteps.currentStep);
      }
    } catch (error) {
      singleSideWithdrawSteps.currentStep.status = STEP_STATUS.ERROR;
      throw Error(error);
    }
  }

  return {
    singleSideWithdrawSteps,
    setSingleSideWithdrawStore,
    prepareSteps,
    runSteps,
    $reset,
  };
});
