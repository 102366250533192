import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import {
  getLiquidityDelegateContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { getLiquidityDelegateAddress } from '@/helpers/address.helper';
import { applySlippageInPercents, toWei } from '@/sdk/utils';
import { safeDateNowPlusEstimatedMinutes } from '@/helpers/utils';
import { Token } from '@/sdk/entities/token';
import { useSingleSide } from '@/store/modules/single-side/useSingleSide';

type DelegateDepositMethodCallArgs = {
  tokens: string[]; // tokens addresses
  amountsIn: string[];
  minAmountOut: string;
  to: string;
  deadline: number;
  portfolio: string; // portfolio address
  farm: string; // farm address
};

type OverridesForContract = {
  value: string | number;
};

export function useSingleSideDepositTransaction() {
  const { walletState } = useEVMWallet();
  const singleSideStore = useSingleSide();

  async function createDepositTransaction(
    callArgs: DelegateDepositMethodCallArgs,
    overrides: OverridesForContract,
  ): Promise<ethers.providers.TransactionResponse> {
    const liquidityDelegateContract: ethers.Contract = getLiquidityDelegateContract(
      getLiquidityDelegateAddress(),
      getInstance().web3.getSigner(),
    );

    console.groupCollapsed(
      '[LIQUIDITY DELEGATE] delegateDeposit : ',
      liquidityDelegateContract.address,
    );
    console.table(callArgs);
    console.table(overrides);
    console.groupEnd();

    const transactionResponse = await transactionWithEstimatedGas(
      liquidityDelegateContract,
      'delegateDeposit',
      Object.values(callArgs),
      overrides,
    );

    return transactionResponse;
  }

  function getDelegateDepositMethodCallArgs({
    selectedTokens,
    lpAmountOutInWei,
    portfolioAddress,
    farmAddress,
  }: {
    selectedTokens: { token: Pick<Token, 'address' | 'decimals'>; amount: BigNumber }[];
    lpAmountOutInWei: BigNumber;
    portfolioAddress: string;
    farmAddress: string;
  }): DelegateDepositMethodCallArgs {
    const tokens: string[] = [];
    const amountsIn: string[] = [];

    selectedTokens.forEach(({ token: { address, decimals }, amount }) => {
      tokens.push(address);
      amountsIn.push(toWei(amount, decimals).toFixed(0));
    });

    const minAmountOut = applySlippageInPercents(
      lpAmountOutInWei,
      singleSideStore.settings.slippageTolerance,
    ).toFixed(0);

    return {
      tokens,
      amountsIn,
      minAmountOut,
      to: walletState.account ?? '',
      deadline: safeDateNowPlusEstimatedMinutes(+singleSideStore.settings.transactionDeadline),
      portfolio: portfolioAddress,
      farm: farmAddress,
    };
  }

  return {
    createDepositTransaction,
    getDelegateDepositMethodCallArgs,
  };
}
