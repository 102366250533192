import { computed, ref } from 'vue';
import BigNumber from 'bignumber.js';
import { Token } from '@/sdk/entities/token';
import { Portfolio, TokenInfo } from '@/sdk/entities/portfolio';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { fromWei } from '@/sdk/utils';
import { useFarmingTokens } from '@/store/modules/tokens/useFarmingTokens';
import { BIG_ONE, BIG_ZERO, max } from '@/utils/bigNumber';
import { SingleSideToken } from '../models/single-side-token';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
  table: (tabularData: any, properties?: readonly string[] | undefined) => {
    if (isLoggingDisabled()) return;

    console.table(tabularData, properties);
  },
};

export function useSingleSideWithdrawTokens() {
  const farmingTokensStore = useFarmingTokens();

  const farmsBySingleSideToken = ref<Record<string, PortfolioFarm> | {}>({});

  const tokens = computed<SingleSideToken[]>(() => {
    farmsBySingleSideToken.value = {};

    return farmingTokensStore.tokens.map<SingleSideToken>(farmingToken => {
      LOGGER.groupCollapsed(
        `[SINGLE SIDE WITHDRAW] INIT | `,
        `PORTFOLIO : ${farmingToken.farm.portfolio.name} | `,
        `TOKEN : ${farmingToken.tokenInfo.token.unwrapWETH().symbol}`,
      );
      const tokenInfo = farmingToken.tokenInfo as TokenInfo;
      const token: Token = tokenInfo.token.unwrapWETH();
      const singleSideTokenId = `${farmingToken.farm.farm}-${token.address}`;
      const portfolio = farmingToken.farm.portfolio as Portfolio;
      const noFeeLimitInTokenWei = calculateWithdrawNoFeeLimit(
        portfolio.totalValueWithProtocolFee,
        tokenInfo,
      );
      // BASE WEI / TOKEN WEI
      const tokenPriceBaseInWei = tokenInfo.price.shiftedBy(
        portfolio.baseToken.decimals - token.decimals,
      );
      const noFeeLimitInUSD = fromWei(
        noFeeLimitInTokenWei.multipliedBy(tokenPriceBaseInWei),
        portfolio.baseToken.decimals,
      ).multipliedBy(portfolio.priceInUSD);
      const balanceLPInWallet = BigNumber(farmingToken.farm.portfolio.balanceOfWallet);
      const balanceLPInFarm = BigNumber(farmingToken.farm.deposited);

      farmsBySingleSideToken.value[singleSideTokenId] = farmingToken.farm;
      LOGGER.groupEnd();

      return {
        id: singleSideTokenId,
        portfolioToken: token,
        isCrossChain: portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN,
        balance: BIG_ZERO,
        balanceLPInWallet,
        balanceLPInFarm,
        targetWeight: tokenInfo.targetWeight,
        apr: BigNumber(farmingToken.farm.printIPR),
        noFeeLimit: BigNumber(
          fromWei(noFeeLimitInTokenWei, token.decimals).toFixed(
            token.decimals,
            BigNumber.ROUND_DOWN,
          ),
        ),
        noFeeLimitInUSD,
        portfolio: {
          name: portfolio.name,
          address: portfolio.contractAddress,
        },
      };
    });
  });

  return {
    tokens,
    farmsBySingleSideToken,
  };
}

/**
 * returns withdraw no fee limit in token wei units
 */
function calculateWithdrawNoFeeLimit(
  portfolioValueWei: BigNumber,
  tokenInfo: TokenInfo,
): BigNumber {
  // BASE WEI / TOKEN WEI
  const withdrawPriceInWei = tokenInfo
    .getWithdrawPrice()
    .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals);

  // Ri * POi - V0 * Wi
  const moreOfTokenInBaseWei = tokenInfo.baseTokenAmountEquivalent.minus(
    portfolioValueWei.multipliedBy(tokenInfo.targetWeight),
  );
  // ( Ri * POi - V0 * Wi ) / ( 1 - Wi ) / PWi
  const maxNoFeeLimitInTokenWei = moreOfTokenInBaseWei
    .div(BIG_ONE.minus(tokenInfo.targetWeight))
    .div(withdrawPriceInWei);
  // max( ( Ri * POi - V0 * Wi ) / ( 1 - Wi ) / PWi, 0 )
  const noFeeLimit = max(maxNoFeeLimitInTokenWei, 0);

  LOGGER.groupCollapsed(
    `[SINGLE SIDE WITHDRAW] NO_FEE_LIMIT : ${tokenInfo.token.unwrapWETH().symbol} : `,
    noFeeLimit.shiftedBy(-tokenInfo.token.decimals).toString(),
  );
  LOGGER.log('( Ri * POi - V0 * Wi ) / ( 1 - Wi ) / PWi');
  LOGGER.table({
    'Ri * POi [ BASE WEI ]': tokenInfo.baseTokenAmountEquivalent.toString(),
    'V0 [ BASE WEI ]': portfolioValueWei.toString(),
    'Wi [ SHARE ]': tokenInfo.targetWeight.toString(),
    'PWi [ BASE WEI / TOKEN WEI ]': withdrawPriceInWei.toString(),
    'CALC NO FEE LIMIT [ TOKEN WEI ]': maxNoFeeLimitInTokenWei.toFixed(),
    ' ': ' ',
    'Ri * POi [ BASE ]': tokenInfo.baseTokenAmountEquivalent
      .shiftedBy(-tokenInfo.baseToken.decimals)
      .toString(),
    'V0 [ BASE ]': portfolioValueWei.shiftedBy(-tokenInfo.baseToken.decimals).toString(),
    'Wi [ % ]': tokenInfo.targetWeight.multipliedBy(100).toString(),
    'POi [ BASE / TOKEN ]': tokenInfo.getWithdrawPrice().toString(),
    'CALC NO FEE LIMIT [ TOKEN ]': maxNoFeeLimitInTokenWei
      .shiftedBy(-tokenInfo.token.decimals)
      .toString(),
  });
  LOGGER.groupEnd();

  return noFeeLimit;
}

// DEBUG
function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].SINGLE_SIDE_WITHDRAW;
}
