import BigNumber from 'bignumber.js';
import { Token } from '@/sdk/entities/token';
import { getPortfolioAndPairRegistryContract } from '@/helpers/contract.helper';

type WithdrawMethodParams = {
  tokenAddresses: string[];
  lptAmounts: string[];
  portfolioAddress: string;
};

export function usePortfolioEstimateWithdraw() {
  async function fetchExactTokenWeiAmountsAfterWithdraw(
    portfolioAddress: string,
    withdrawTokens: { token: Pick<Token, 'address' | 'decimals'>; lpAmountInWei: BigNumber }[],
  ): Promise<string[]> {
    const parameters = getWithdrawMethodParameters(portfolioAddress, withdrawTokens);

    console.groupCollapsed('fetchExactTokenWeiAmountsAfterWithdraw');
    console.table(parameters);
    console.groupEnd();

    const registryContract = getPortfolioAndPairRegistryContract();

    // NOTE: LOCAL: bnAmounts in token`s decimals
    const bnAmounts = await registryContract.callStatic.estimateWithdraw(
      parameters.tokenAddresses,
      parameters.lptAmounts,
      parameters.portfolioAddress,
    );

    console.log(
      '[fetchExactTokenWeiAmountsAfterWithdraw] bnAmounts : ',
      bnAmounts.map(amount => amount.toString()),
    );

    return bnAmounts.map(amount => amount.toString());
  }

  return {
    fetchExactTokenWeiAmountsAfterWithdraw,
  };
}

function getWithdrawMethodParameters(
  portfolioAddress: string,
  withdrawTokens: { token: Pick<Token, 'address' | 'decimals'>; lpAmountInWei: BigNumber }[],
): WithdrawMethodParams {
  const tokenAddresses: string[] = [];
  const lptAmounts: string[] = [];

  withdrawTokens.forEach(({ token, lpAmountInWei }) => {
    tokenAddresses.push(token.address);
    lptAmounts.push(lpAmountInWei.toFixed(0));
  });

  return {
    tokenAddresses,
    lptAmounts,
    portfolioAddress,
  };
}
