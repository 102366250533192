import { defineStore } from 'pinia';
import { useMilkomedaWSCBridge } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCBridge';

export const useSingleSideMilkomedaWSCBridge = defineStore('singleSideMilkomedaWSCBridge', () => {
  const milkomedaWSCBridge = useMilkomedaWSCBridge();

  return {
    ...milkomedaWSCBridge,
  };
});
