import BigNumber from 'bignumber.js';
import { Token } from '@/sdk/entities/token';
import { getPortfolioAndPairRegistryContract } from '@/helpers/contract.helper';
import { toWei } from '@/sdk/utils';

type DepositMethodParams = {
  tokensAddresses: string[];
  amountsIn: string[];
  portfolioAddress: string;
};

export function usePortfolioEstimateDeposit() {
  async function fetchExactLpWeiAmountAfterDeposit(
    portfolioAddress: string,
    selectedTokens: { token: Pick<Token, 'address' | 'decimals'>; amount: BigNumber }[],
  ): Promise<string> {
    const parameters = getDepositMethodParameters(portfolioAddress, selectedTokens);

    console.groupCollapsed('fetchExactLpWeiAmountAfterDeposit');
    console.table(parameters);
    console.groupEnd();

    const registryContract = getPortfolioAndPairRegistryContract();

    const bnAmount = await registryContract.callStatic.estimateDeposit(
      parameters.tokensAddresses,
      parameters.amountsIn,
      parameters.portfolioAddress,
    );

    console.log('[fetchExactLpWeiAmountAfterDeposit] bnAmount : ', bnAmount.toString());
    return bnAmount.toString();
  }

  return {
    fetchExactLpWeiAmountAfterDeposit,
  };
}

function getDepositMethodParameters(
  portfolioAddress: string,
  selectedTokens: { token: Pick<Token, 'address' | 'decimals'>; amount: BigNumber }[],
): DepositMethodParams {
  const tokensAddresses: string[] = [];
  const amountsIn: string[] = [];

  selectedTokens.forEach(({ token, amount }) => {
    tokensAddresses.push(token.address);
    //amountsIn in token decimals
    amountsIn.push(toWei(amount, token.decimals).toFixed(0));
  });

  return {
    tokensAddresses,
    amountsIn,
    portfolioAddress,
  };
}
