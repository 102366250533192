import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { STEP_STATUS, STEP_TYPE, StepInfo } from '@/components/stepper/stepper.types';
import { useSingleSideMilkomedaWSCBridge } from '@/store/modules/single-side/useSingleSideMilkomedaWSCBridge';
import { useSingleSideDeposit } from './useSingleSideDeposit';

interface ISingleSideDepositStepsState {
  steps: StepInfo[];
  currentStep: StepInfo | null;
  isShown: boolean;
}

type SingleSideDeposit = ReturnType<typeof useSingleSideDeposit>;

export const useSingleSideDepositSteps = defineStore('singleSideDepositSteps', () => {
  const { t } = useI18n();
  const { milkomedaWSCBridgeState, doBridgeFromCardano } = useSingleSideMilkomedaWSCBridge();

  let nextStep = {};
  let singleSideDeposit: SingleSideDeposit | null = null;

  const doStepAction = {
    [STEP_TYPE.BRIDGE]: async () => {
      await doBridgeFromCardano();
    },
    [STEP_TYPE.APPROVE]: async () => {
      await singleSideDeposit!.setAllowance();
    },
    [STEP_TYPE.SIGN]: async () => {
      await singleSideDeposit!.doDeposit();
    },
  };

  const singleSideDepositSteps = reactive<ISingleSideDepositStepsState>({
    steps: [],
    currentStep: null,
    isShown: false,
  });

  function $reset() {
    singleSideDeposit = null;
    nextStep = {};
    singleSideDepositSteps.steps = [];
    singleSideDepositSteps.currentStep = null;
    singleSideDepositSteps.isShown = false;
  }

  // BRIDGE
  function addBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCBridgeState.needBridge) {
      const step = {
        name: t('singleSide.steps.bridge'),
        type: STEP_TYPE.BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      singleSideDepositSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      return {
        prevStep: STEP_TYPE.BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // APPROVE
  function addApproveStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (!singleSideDeposit!.singleSideForm.hasAllowance) {
      const step = {
        name: t('singleSide.steps.approve'),
        type: STEP_TYPE.APPROVE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      singleSideDepositSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.APPROVE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // SIGN
  function addSignStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    const step = {
      name: t('singleSide.steps.sign.deposit'),
      type: STEP_TYPE.SIGN,
      status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
    };
    singleSideDepositSteps.steps.push(step);
    // set current step
    if (!currentStep) {
      currentStep = step;
    }
    // next step
    if (prevStep) {
      nextStep[prevStep] = step;
    }

    return {
      prevStep: STEP_TYPE.SIGN,
      currentStep,
    };
  }

  function setSingleSideDepositStore(store: SingleSideDeposit) {
    singleSideDeposit = store;
  }

  function prepareSteps(): void {
    let step: StepInfo | null = null;
    let prevStep: STEP_TYPE | null = null;

    let state = addBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addApproveStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addSignStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    // next step
    if (prevStep) {
      nextStep[prevStep] = null;
    }

    singleSideDepositSteps.currentStep = step;
  }

  async function doStep(step: StepInfo) {
    step.status = STEP_STATUS.PROGRESS;
    await doStepAction[step.type]();
    step.status = STEP_STATUS.SUCCESS;
    singleSideDepositSteps.currentStep = nextStep[step.type];
  }

  async function runSteps() {
    if (!singleSideDepositSteps.currentStep) return;

    try {
      while (singleSideDepositSteps.currentStep) {
        await doStep(singleSideDepositSteps.currentStep);
      }
    } catch (error) {
      singleSideDepositSteps.currentStep.status = STEP_STATUS.ERROR;
      throw Error(error);
    }
  }

  return {
    singleSideDepositSteps,
    setSingleSideDepositStore,
    prepareSteps,
    runSteps,
    $reset,
  };
});
